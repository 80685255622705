const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
const axios = require('axios');

const wave = document.getElementById('bgwave');
const tab = document.getElementsByClassName('tab-header')[0];
const forgotPassBody = document.querySelector('.forgot-pass-body');
const forgotPassTrigger = document.querySelector('.forgot-pass');

// const regiError = document.getElementById('regiError');
// const forgetPassword = document.getElementById('forgetPassword');
// const forgetUserName = document.getElementById('fogetFullName');
// const forgetUserEmail = document.getElementById('forgetEmail');
// const resetBtn = document.getElementById('reset');
// const resetErrors = document.getElementById('resetError');
const registerBtn = document.getElementById('registerBtn');
const loginBtn = document.getElementById('loginBtn');
const instantBtn = document.getElementById('instantBtn');
const registerForm = document.getElementById('registerForm');
const loginForm = document.getElementById('loginForm');
const instantAccess = document.getElementById('instantAccess');


const SW9 = new SiriWave({
  style: 'ios9',
  container: wave,
  width: wave.clientWidth || window.innerWidth,
  height: wave.clientHeight || 730,
  autostart: true,
});

async function siteSettings() {
  const SITE_ID = getSiteIdFromStorage();

  if (!SITE_ID) {
    console.warn('SITE_ID not found in storage. Using default settings.');
    return getDefaultSettings();
  }

  const settings = await fetchSiteSettings(SITE_ID);

  return settings || getDefaultSettings();
}

function getDefaultSettings() {
  // Return a default settings object
  return {
    templateSettings: {
      showLoginRegister: '0',
      selectedTemplate: 'generic'
    },
    redirectWebsite: {
      url: ''
    },
    defaultVideo: {
      id: "112836958"
    }
  };
}

async function applyTemplateSettings() {
  try {
    // Fetch API settings; if undefined, use fallback data
    const tempSettings = await siteSettings() || getDefaultSettings();
    
    // Ensure template settings are available
    const selectedTemplate = tempSettings?.templateSettings?.selectedTemplate;
  
    if (!selectedTemplate) throw new Error('Selected template is missing in the settings');

    // Select layout elements based on template settings
    const layouts = {
      tv: document.querySelector('.tv-layout'),
      tablet: document.querySelector('.tablet-layout'),
      generic: document.querySelector('.generic-layout'),
      png: document.querySelector('.png-frame-layout')
    };

    // Hide all layouts initially
    Object.values(layouts).forEach(layout => {
      if (layout) layout.style.display = 'none';
    });

    // Activate the selected layout
    const activeLayout = layouts[selectedTemplate];
    if (activeLayout) {
      activeLayout.style.display = 'block';
      activeLayout.classList.add('active-layout');
    } else {
      console.error('Unknown template selected:', selectedTemplate);
    }

  } catch (error) {
    console.error('Error while applying template settings:', error);
  }
}


//@desc marque and preloader
// $(document).ready(() => {
//   applyTemplateSettings();

//   $('.loader').fadeOut('slow', () => {
//     $('#after-preloder').removeClass('hidden');
//     $('.player-body').addClass('animate__animated animate__zoomIn');

//   });
// });

document.addEventListener('DOMContentLoaded', () => {
  const loader = document.querySelector('.loader');
  const afterPreloder = document.getElementById('after-preloder');
  const playerBody = document.querySelector('.player-body');

  siteManager.updateSiteId();
  applyTemplateSettings();

  function fadeOut(element, duration) {
    return new Promise(resolve => {
      element.style.transition = `opacity ${duration}ms`;
      element.style.opacity = 0;
      setTimeout(() => {
        element.style.display = 'none';
        resolve();
      }, duration);
    });
  }

  fadeOut(loader, 600).then(() => {
    afterPreloder.classList.remove('hidden');
    playerBody.classList.add('animate__animated', 'animate__zoomIn');
  });
});


let videoIds = [];
let currentVideoIndex = 0;
let player;

async function fetchVideoIds() {
  const SITE_ID = getSiteIdFromStorage();

  if (!SITE_ID) {
    console.warn('No SITE_ID found');
    return null;
  }

  try {
    const response = await window.fetchSiteSettings(SITE_ID);

    if (response !== null) {
      const templateType = response.templateSettings.selectedTemplate;
      const fetchedIds = response.templateSettings[templateType]?.videoIds;

      if (!fetchedIds) {
        console.warn(`No video IDs found for template type: ${templateType}`);
        return null;
      }

      return Array.isArray(fetchedIds) ? fetchedIds : [fetchedIds];
    }
  } catch (error) {
    console.error('Error fetching site settings:', error);
    return null;
  }
}

function processVideoIds(ids) {
  if (!ids) return [];
  const flattenedIds = Array.isArray(ids) ? ids.flatMap(id => String(id).split(',')) : String(ids).split(',');
  return flattenedIds.map(id => id.trim()).filter(Boolean);
}

function initializePlayer() {
  if (videoIds.length === 0) {
    console.error('No valid video IDs available');
    return;
  }

  player = new Vimeo.Player("mainVimeoPlyr", {
    id: videoIds[currentVideoIndex],
    width: 144,
    height: 92,
    controls: false,
    title: false,
    loop: false,
    autoplay: true,
  });

  player.on('ended', handleVideoEnd);
  player.on('error', handlePlayerError);
  // player.on('loaded', id => console.log('Video loaded with ID:', id));

  initializeVolumeControl();
  initializePlaybackControls();
}

function initializeVolumeControl() {
  const indexvol = document.getElementById('indexvolume');
  if (indexvol) {
    noUiSlider.create(indexvol, {
      connect: 'lower',
      direction: 'ltr',
      range: {
        max: 100,
        min: 0,
      },
      start: 50,
    });

    indexvol.noUiSlider.on('update', (values, handle) => {
      const volume = values[handle] / 100;
      if (player && typeof player.setVolume === 'function') {
        player.setVolume(volume);
      }
    });

    // Set initial volume
    player.setVolume(0.7);
  }
}

function initializePlaybackControls() {
  const videoTrigger = document.querySelector('.video_trigger');
  if (videoTrigger) {
    videoTrigger.addEventListener('click', togglePlayPause);
  }
}

function togglePlayPause() {
  if (player && typeof player.getPaused === 'function') {
    player.getPaused().then((paused) => {
      if (paused) {
        player.play();
      } else {
        player.pause();
      }
    }).catch((error) => {
      console.error('Error toggling play/pause:', error);
    });
  }
}

function handleVideoEnd() {
  currentVideoIndex = (currentVideoIndex + 1) % videoIds.length;
  loadNextVideo();
}

function loadNextVideo() {
  const nextId = videoIds[currentVideoIndex];
  // console.log('Loading next video with ID:', nextId);

  player.loadVideo(nextId)
    .then(() => player.play())
    .catch(handlePlayerError);
}

function handlePlayerError(error) {
  console.error('Vimeo player error:', error);
  handleVideoEnd();
}

async function setup() {
  try {
    const fetchedIds = await fetchVideoIds();
    console.log(fetchedIds);
    videoIds = processVideoIds(fetchedIds);

    if (videoIds.length === 0) {
      const defvideo = await siteSettings();
      videoIds = processVideoIds(defvideo.defaultVideo.id);
      initializePlayer();
      //throw new Error('No valid video IDs received from the API');
      return;
    }
    initializePlayer();
  } catch (error) {
    console.error('Error setting up the player:', error);
  }
}

window.addEventListener('load', setup);

// Login Registration page Js
if (tab) {
  const tabPanes = tab.getElementsByTagName('div');
  for (let i = 0; i < tabPanes.length; i++) {
    tabPanes[i].addEventListener('click', () => {
      document
        .getElementsByClassName('tab-header')[0]
        .getElementsByClassName('active')[0]
        .classList.remove('active', 'animate__animated', 'animate__flipInY');
      tabPanes[i].classList.add('active');

      document
        .getElementsByClassName('tab-content')[0]
        .getElementsByClassName('active')[0]
        .classList.remove('active', 'animate__animated', 'animate__flipInY');
      document
        .getElementsByClassName('tab-content')[0]
        .getElementsByClassName('tab-body')[i].classList.add('active', 'animate__animated', 'animate__flipInY');
    });
  }
}

// forgot password body
if (forgotPassTrigger) {
  forgotPassTrigger.addEventListener('click', () => {
    forgotPassBody.classList.add('active', 'animate__animated', 'animate__flipInY');
    document
      .getElementsByClassName('tab-content')[0]
      .getElementsByClassName('active')[0]
      .classList.remove('active', 'animate__animated', 'animate__flipInY');
  });
}


document.addEventListener('click', async (event) => {
  const activeLayout = document.querySelector('.active-layout');
  if (!activeLayout || !event.target.matches('.active-layout .triger-button')) {
    const loginForm = document.getElementById('loginForm');

    // Hide the login form if it's displayed and the click happened outside of it
    if (loginForm && loginForm.style.display !== 'none' && !loginForm.contains(event.target)) {
      loginForm.style.display = 'none';
    }

    return;
  }
  event.preventDefault();
  try {
    const tempSettings = await siteSettings();

    if (tempSettings && tempSettings.templateSettings.showLoginRegister === '1') {
      if (tempSettings.redirectWebsite && tempSettings.redirectWebsite.url) {
        window.location.href = tempSettings.redirectWebsite.url;
        return;
      }
    }

    const loginForm = document.getElementById('loginForm');
    if (!loginForm) {
      console.error('Login form not found');
      return;
    }

    loginForm.style.removeProperty('display');
    loginForm.classList.add('animate__animated', 'animate__bounceInUp');
    loginForm.style.display = 'flex';
  } catch (error) {
    console.error('Error handling click:', error);
  }
}, { passive: false });



// animation shaking with time out
function startShakeAnimation() {
  const headphone = document.querySelector('.home-headphone-shake');
  if (!headphone) return;

  const animate = () => {
    headphone.style.animationPlayState = 'running';
    setTimeout(() => {
      headphone.style.animationPlayState = 'paused';
    }, 5000);
  };

  animate();
  setInterval(animate, 8000);
}

startShakeAnimation();

// Utility functions
const utils = {
  validateEmail: (email) => /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(email),
  validatePhone: (phone) => /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(phone),
  showError: (message, elementId, duration = 3000) => {
    const errorElement = document.getElementById(elementId);
    if (errorElement) {
      errorElement.textContent = message;
      errorElement.style.display = 'block';
      setTimeout(() => { errorElement.style.display = 'none'; }, duration);
    }
  },
  showSwalAlert: (options) => {
    Swal.fire({
      position: 'center',
      showConfirmButton: false,
      timer: 1800,
      backdrop: false,
      ...options
    });
  }
};

// Form validation
const validateForm = (data, schema) => {
  const errors = {};
  Object.keys(schema).forEach(field => {
    const value = data[field];
    const rules = schema[field];
    if (rules.required && !value) {
      errors[field] = `${field} is required.`;
    } else if (rules.minLength && value.length < rules.minLength) {
      errors[field] = `${field} should be at least ${rules.minLength} characters long.`;
    } else if (rules.validate && !rules.validate(value)) {
      errors[field] = `Invalid ${field} format.`;
    }
  });
  return errors;
};

// API calls
const api = {
  post: (url, data) => axios.post(url, data),
};

// Form handlers
const formHandlers = {
  register: {
    validate: (data) => validateForm(data, {
      fullName: { required: true },
      email: { required: true, validate: utils.validateEmail },
      phone: { required: true, validate: utils.validatePhone },
      registerPassword: { required: true, minLength: 8 }
    }),
    submit: (formData, siteId) => {
      // const siteId = localStorage.getItem('SITE_ID');
      if (!siteId) {
        utils.showSwalAlert({
          icon: 'error',
          title: 'Registration Failed',
          text: 'This website is not registered with our application.'
        });
        return;
      }
      formData.siteId = siteId;
      formData.url = window.location.protocol + "//" + window.location.host;
      formData.siteTitle = document.title;

      api.post(`${MAIN_URL}/api/register`, formData)
        .then(response => {
          if (response.data.success) {
            utils.showSwalAlert({
              icon: 'success',
              title: response.data.message
            });
            setTimeout(() => {
              window.location.href = response.data.redirectUrl;
            }, 2000);
          }
        })
        .catch(error => {
          console.error('Registration error:', error);
          utils.showSwalAlert({
            icon: 'error',
            title: 'Registration Failed',
            text: 'Please try again later.'
          });
        });
    }
  },
  login: {
    validate: (data) => validateForm(data, {
      email: { required: true, validate: utils.validateEmail },
      password: { required: true }
    }),
    submit: (formData, siteId) => {
      formData.siteId = siteId;
      api.post(`${MAIN_URL}/api/login`, formData)
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('user_id', response.data.user_id);
            localStorage.setItem('rememberMeToken', response.data.rememberMeToken);
            utils.showSwalAlert({
              icon: 'success',
              title: response.data.message
            });
            setTimeout(() => {
              if (typeof response.data.redirectUrl === 'string' && response.data.redirectUrl.trim() !== '') {
                window.location.href = response.data.redirectUrl.trim();
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Redirect Error',
                  text: 'Unable to redirect. Please contact support.',
                });
              }
            }, 2000);
          } else {
            utils.showSwalAlert({
              icon: 'error',
              title: 'Login Failed',
              text: response.data.message || 'Invalid credentials. Please try again.'
            });
          }
        })
        .catch(error => {
          console.error('Login error:', error);
          let errorMessage = 'An unexpected error occurred. Please try again later.';
          if (error.response) {
            errorMessage = error.response.data.message || 'Server error. Please try again.';
          } else if (error.request) {
            errorMessage = 'No response from server. Please check your internet connection.';
          }
          utils.showError(errorMessage, 'loginError');
        });
    }
  },
  instantAccess: {
    validate: (data) => validateForm(data, {
      instantkey: { required: true }
    }),
    submit: (formData, siteId) => {
      formData.siteId = siteId;
      api.post(`${MAIN_URL}/api/instant`, formData)
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('user_id', response.data.user_id);
            localStorage.setItem('rememberMeToken', response.data.rememberMeToken);
            utils.showSwalAlert({
              icon: 'success',
              title: response.data.message
            });
            setTimeout(() => {
              if (typeof response.data.redirectUrl === 'string' && response.data.redirectUrl.trim() !== '') {
                window.location.href = response.data.redirectUrl.trim();
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Redirect Error',
                  text: 'Unable to redirect. Please contact support.',
                });
              }
            }, 2000);
          } else {
            utils.showSwalAlert({
              icon: 'error',
              title: 'Login Failed',
              text: response.data.message || 'Invalid credentials. Please try again.'
            });
          }
        })
        .catch(error => {
          console.error('Instant access error:', error);
          let errorMessage = 'An unexpected error occurred. Please try again later.';
          if (error.response) {
            errorMessage = error.response.data.message || 'Server error. Please try again.';
          } else if (error.request) {
            errorMessage = 'No response from server. Please check your internet connection.';
          }
          utils.showError(errorMessage, 'instantError');
        });
    }
  }
};

// Event listeners
document.addEventListener('DOMContentLoaded', () => {

  if (registerBtn && registerForm) {
    registerBtn.addEventListener('click', (event) => {
      event.preventDefault();
      const formData = {
        fullName: document.getElementById('fullName').value.trim(),
        email: document.getElementById('registerEmail').value.trim(),
        company: document.getElementById('company').value.trim(),
        userName: document.getElementById('userName').value.trim(),
        phone: document.getElementById('phone').value.trim(),
        city: document.getElementById('city').value.trim(),
        state: document.getElementById('state').value.trim(),
        zip: document.getElementById('zip').value.trim(),
        address: document.getElementById('address').value.trim(),
        registerPassword: document.getElementById('registerPassword').value.trim(),
      };
      const errors = formHandlers.register.validate(formData);
      if (Object.keys(errors).length === 0) {
        const siteId = getSiteIdFromStorage();
        formHandlers.register.submit(formData, siteId);
      } else {
        utils.showError(Object.values(errors)[0], 'regiError');
      }
    });
  }

  if (loginBtn && loginForm) {
    loginBtn.addEventListener('click', (event) => {
      event.preventDefault();
      const formData = {
        email: document.getElementById('email').value.trim(),
        password: document.getElementById('password').value.trim(),
        checkboxval: document.getElementById('loginChecked').checked
      };
      const errors = formHandlers.login.validate(formData);
      if (Object.keys(errors).length === 0) {
        const siteId = getSiteIdFromStorage();
        formHandlers.login.submit(formData, siteId);
      } else {
        utils.showError(Object.values(errors)[0], 'loginError');
      }
    });
  }

  if (instantBtn && instantAccess) {
    instantBtn.addEventListener('click', (event) => {
      event.preventDefault();
      const formData = {
        instantkey: document.getElementById('instantKey').value.trim(),
        checkboxval: document.getElementById('instantChecked').checked
      };
      const errors = formHandlers.instantAccess.validate(formData);
      if (Object.keys(errors).length === 0) {
        const siteId = getSiteIdFromStorage();
        formHandlers.instantAccess.submit(formData, siteId);
      } else {
        utils.showError(Object.values(errors)[0], 'InstantError');
      }
    });
  }
});


async function verifyTokenAndRedirect() {
  try {
    const token = localStorage.getItem('rememberMeToken');
    const siteId = getSiteIdFromStorage();

    if (!token) {
      throw new Error('Missing token or site ID');
    }

    const response = await fetch(`${MAIN_URL}/api/verify-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ siteId })
    });

    const data = await response.json();

    if (!response.ok || !data.valid) {
      throw new Error(data.reason || 'Invalid token');
    }

    hideElements();
    await delay(800);
    await showWelcomeMessage(data.username);
    window.location.href = data.redirectUrl;

  } catch (error) {
    handleError(error.message);
  }
}

function handleError(errorMessage) {
  console.error(`Error: ${errorMessage}`);
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function showWelcomeMessage(username) {
  await Swal.fire({
    title: `Welcome, ${username}!`,
    text: 'You will be redirected shortly.',
    icon: 'success',
    timer: 3000,
    showConfirmButton: false,
    backdrop: false,
  });
}

function hideElements() {
  const elementsToHide = [
    '.center-volume',
    '.player-headphone-section',
    '.triger-button',
    '.player-mic-container'
  ];

  elementsToHide.forEach(selector => {
    const element = document.querySelector(selector);
    if (element) {
      element.style.display = 'none';
    }
  });
}

document.addEventListener('DOMContentLoaded', verifyTokenAndRedirect);